body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
}

.cardWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, max-content));
  grid-gap: 16px;
  justify-content: center;
  padding: initial;
  width: 100%;
}

.login, .bikeInputForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login {
  margin-top: 200px;
}

.ctaAdd {
  display: block !important;
  margin: 0 auto !important;
  width: 150px;
  margin-top: 1rem !important;
}

.swing {
	transform-origin: top center;
	animation: swing 2s ease infinite;
}
@keyframes swing {
	20% { transform: rotate(15deg); }	
	40% { transform: rotate(-10deg); }
	60% { transform: rotate(5deg); }	
	80% { transform: rotate(-5deg); }	
	100% { transform: rotate(0deg); }
}